.Footer {
    display: flex;
    justify-content: space-around;
    align-items: center;
    position: fixed;
    bottom: 0;
    padding: .5rem;
    width: 100%;

    a {
        text-decoration: none;
        color: inherit; } }

@media (max-width: 650px) {
    .Footer {
        flex-direction: column;
        font-size: .8rem;
        line-height: 1rem; } }
