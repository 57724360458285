.Landing {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 2rem;
    text-align: center;
    width: 50%;
    margin: 0 auto;

    & > * {
        margin-bottom: 2rem; }

    .logo {
        object-fit: contain;
        width: 400px; } }
