.mainnav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-transform: uppercase;
    height: 2.5rem;
    color: #023d7c;
    position: relative;
    z-index: 999;
    padding: 0 2rem;
    background: #f5ecd3;
    .company {
        font-size: 1.5rem;
        letter-spacing: .25rem;
        text-decoration: none;
        color: inherit;
        margin-right: 2rem;
        font-weight: bold; }

    ul {
        display: flex;
        list-style: none;

        li {
            a {
                display: inline-block;
                padding: 15px;
                color: #023d7c;
                text-decoration: none; } } } }

@media (max-width: 730px) {
    .mainnav {
        .company {
            font-size: 1rem;
            letter-spacing: normal; }

        ul {
            li {
                a {
                    font-size: 1rem;
                    padding: 10px; } } } } }

@media (max-width: 620px) {
    .mainnav {
        padding: 0;

        .company {
            display: none; }

        ul {
            display: flex;
            width: 100%;
            justify-content: space-around;

            li {
                a {
                    font-size: .9rem; } } } } }
